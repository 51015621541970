/**
 * Returns a nested timeline that skips an entire mission of the game
 * @param {number} missionNumber Which mission number we're skipping
 */
export function buildSkipMissionProcedure(missionNumber) {
  return {
    data: { missionNumber, missionNumTrials: 0, skipped: true },
    timeline: [],
  };
}
