import htmlKeyboardResponse from "@jspsych/plugin-html-keyboard-response";
import { instructionImage } from "../../lib/utils";

/** Trial that displays a completion message for 5 seconds */
export const endCreditsScreen = {
  type: htmlKeyboardResponse,
  stimulus: instructionImage("assets/images/postgame/CreditsScreen.svg"),
  choices: "NO_KEYS",
  trial_duration: 30000,
};
