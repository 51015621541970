/**
 * Gets the value of a given variable from the URL search parameters
 * @param {string} queryParameter The key of the variable in the search parameters
 * @returns {string} The value of variable in the search parameters. An empty string if the variable is not found
 */
export function getSearchParam(queryParameter) {
  const params = new URLSearchParams(window.location.search);
  const searchParam = params.get(queryParameter);
  return searchParam;
}

/**
 * Checks for a given key in both the
 * @param {string} key The key to check for
 * @returns {string | undefined}
 */
export function checkEnvOrUrl(key) {
  const urlValue = getSearchParam(key);
  const envValue = process.env[`REACT_APP_${key}`];

  // Both are truthy, use URL with warning
  if (urlValue && envValue) {
    console.warn(
      `${key} was provided in both the environment and url.\n` +
        `Selecting the value found in the url: ${urlValue}`
    );
    return urlValue;
  }
  // Only the URL is truthy, use the URl
  else if (urlValue && !envValue) return urlValue;
  // Only the ENV is truthy, use the ENV
  else if (!urlValue && envValue) return envValue;
  // Neither are truthy, return undefined
  else if (!urlValue && !envValue) return undefined;
}
