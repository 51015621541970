/**
 * @class Score
 * @classdesc Used to keep track of various information about the user's score
 */
export class Score {
  /**
   * Constructs a new instance of the game class
   * @param {JsPsych} jsPsych jsPsych instance being used to run the task
   * @param {number} startingLives The number of lives the user starts with
   * @param {number} rewardReduction The number of points removed when the user loses all their lives
   */
  constructor(jsPsych, startingLives, rewardReduction) {
    /**
     * @type {JsPsych}
     * @description jsPsych instance being used to run the task
     */
    this.jsPsych = jsPsych;

    /**
     * @type {number}
     * @description Number of lives the user begins with
     */
    this.startingLives = startingLives;

    /**
     * @type {number}
     * @description number of points removed when the user resets their lives
     */
    this.rewardReduction = rewardReduction;

    /**
     * @type {number}
     * @description Number of lives the user has remaining
     */
    this.missionLives = startingLives;

    /**
     * @type {number}
     * @description User's current reward
     */
    this.missionReward = 0;

    /**
     * @type {number}
     * @description Number of times the user collected the package successfully
     */
    this.missionPackages = 0;

    /**
     * @type {number}
     * @description Number of times the user crashed
     */
    this.missionCrashes = 0;
  }

  /** Returns the user's score data as an object */
  getScore() {
    return {
      missionLives: this.missionLives,
      missionReward: this.missionReward,
      missionPackages: this.missionPackages,
      missionCrashes: this.missionCrashes,
    };
  }

  /**
   * Adds the user's score to a given data object
   * @param {Object} data
   */
  assignScore(data) {
    Object.assign(data, this.getScore());
  }

  /** Adds a crash to the user score and subtracts a life */
  addCrash() {
    this.missionCrashes++;
    this.missionLives--;

    // Reduce reward and reset lives when the user runs out of lives
    if (this.missionLives <= 0) {
      this.missionLives = this.startingLives;
      this.missionReward -= this.rewardReduction;
    }
  }

  /**
   * Adds a package to the user's score and adds the reward
   * @param {number} reward The reward for the given package
   */
  addPackage(reward) {
    this.missionPackages++;
    this.missionReward += reward;
  }
}
