/**
 * ! Your timeline and options should be built in a newly created file, not this one
 * TODO @brown-ccv: Link "Quick Start" step once's it's built into the docs
 */
import { buildJehliGearshiftTaskTimeline, jehliGearshiftTaskOptions } from "./jehliGearshiftTask";

// Add CSS styling from jsPsych
import "jspsych/css/jspsych.css";
// Add custom CSS styling for this task
import "../lib/markup/trials.css";

/**
 * Experiment-wide settings for jsPsych: https://www.jspsych.org/7.3/overview/experiment-options/
 * Note that Honeycomb combines these with other options required for Honeycomb to operate correctly
 *
 * Custom options for your experiment should be added in your own file inside the experiment folder
 */
export const jsPsychOptions = jehliGearshiftTaskOptions;

/**
 * Builds the experiment's timeline that jsPsych will run
 * The instance of jsPsych passed in will include jsPsychOptions from above
 * @param {JsPsych} jsPsych The jsPsych instance that is running the experiment
 * @param {string} studyID The ID of the study that was just logged into
 * @param {string} participantID The ID of the participant that was just logged in
 * @param {string} sessionID The ID of the current session that was just logged in
 * @param {Object} metadata The global metadata of the experiment
 * @returns The timeline for JsPsych to run
 */
export async function buildTimeline(jsPsych, studyID, participantID, sessionID, metadata) {
  console.log(
    `Building timeline for participant ${participantID} on study ${studyID}. ` +
      `Session number is ${sessionID}`
  );

  const timeline = buildJehliGearshiftTaskTimeline(jsPsych, metadata);
  return timeline;
}
