import React from "react";

// Import css styling
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.css";
import "../gearshiftGame/styles.css";
import "./index.css";

// Import configurations and utilities
import { config } from "../config/main";
import * as trigger from "../config/trigger";
import { loadExternalJSON } from "../lib/utils";

// Import deployment functions
import { METADATA_FILE_URL } from "../gearshiftGame/constants";
import { addToFirebase, validateParticipant } from "./deployments/firebase";

// Import React components
import JsPsychExperiment from "./components/JsPsychExperiment";
import Login from "./components/Login";

/**
 * The top-level React component for Honeycomb. App handles initiating the jsPsych component when the participant
 * successfully logs in, and stores the overall state of the experiment. Importantly, App keeps track of what the
 * experiment is running on (Electron or the Browser/Firebase).
 *
 * Note that App is a functional component, which means it uses React callbacks rather than class methods. Learn more
 * about functional vs. class components here: https://reactjs.org/docs/components-and-props.html. It is recommended
 * to use functional components.
 */
export default function App() {
  // Manage if a user is currently logged in
  const [loggedIn, setLoggedIn] = React.useState(false);
  // Manage the user's login information
  const [loginInformation, setLoginInformation] = React.useState();

  // Manage the method type being used ("desktop", "firebase", or "default")
  const [currentMethod, setMethod] = React.useState("default");

  // The metadata file used to build the experiment
  const [metadata, setMetadata] = React.useState();

  /**
   * This effect is called once, on the first render of the application
   * It checks the environment variables to initialize needed state variables
   * And determines which methods to be using
   */
  React.useEffect(() => {
    async function setUpHoneycomb() {
      // Load the metadata and task files
      // TODO: Need to handle errors when awaiting this (failing to load JSON file)
      // TODO: Login should be disabled while the JSON is loading
      const metadataFile = await loadExternalJSON(METADATA_FILE_URL);
      // const metadataFile = require("../config/metadata.example.json"); // TEMP: Use local metadata example
      setMetadata(metadataFile);

      // For testing and debugging purposes
      console.log("Honeycomb Configuration", config);
      console.log("Metadata Configuration", metadataFile);

      if (config.USE_ELECTRON) {
        // RUNNING ON A LOCAL APP

        // Pass settings to the Electron main process
        await window.electronAPI.setConfig(config);
        await window.electronAPI.setTrigger(trigger);

        setMethod("desktop");
      } else {
        // RUNNING ON THE BROWSER
        if (config.USE_FIREBASE) setMethod("firebase");
        else setMethod("default");
      }
    }
    setUpHoneycomb();
  }, []);

  /** VALIDATION FUNCTIONS */
  // Default to valid
  const defaultValidation = async (studyID, participantID, sessionID) => {
    console.log("Validating login: ", { studyID, participantID, sessionID });
    return true;
  };
  // Validate participant/study against Firestore rules
  const firebaseValidation = async (studyID, participantID, sessionID) => {
    console.log("Validating login: ", { studyID, participantID, sessionID });
    return validateParticipant(studyID, participantID);
  };

  /** DATA WRITE FUNCTIONS */

  // Default to no operation
  const defaultFunction = () => {};
  // Add trial data to Firestore (see src/App/deployments/firebase.js)
  const firebaseUpdateFunction = (data) => {
    addToFirebase(data);
  };
  // Execute the 'on_data_update' callback function (see public/electron/main.js)
  const desktopUpdateFunction = async (data) => {
    await window.electronAPI.on_data_update(data);
  };

  /** EXPERIMENT FINISH FUNCTIONS */

  // Save the experiment data on the desktop
  const defaultFinishFunction = (data) => {
    data.localSave("csv", "task.csv");
  };
  // Do nothing
  const firebaseFinishFunction = () => {};
  // Execute the 'on_finish' callback function (see public/electron/main.js)
  const desktopFinishFunction = async () => {
    await window.electronAPI.on_finish();
  };

  /**
   * Callback function executed when the user logs in.
   *
   * The study and participant IDs are updated and loggedIn is set to true.
   */
  const handleLogin = React.useCallback((studyID, participantID, sessionID) => {
    setLoginInformation({ studyID, participantID, sessionID });
    setLoggedIn(true);
  }, []);

  if (loggedIn) {
    return (
      <JsPsychExperiment
        loginInformation={loginInformation}
        metadata={metadata}
        dataUpdateFunction={
          {
            desktop: desktopUpdateFunction,
            firebase: firebaseUpdateFunction,
            default: defaultFunction,
          }[currentMethod]
        }
        dataFinishFunction={
          {
            desktop: desktopFinishFunction,
            firebase: firebaseFinishFunction,
            default: defaultFinishFunction,
          }[currentMethod]
        }
      />
    );
  } else {
    return (
      <Login
        validationFunction={
          {
            desktop: defaultValidation,
            default: defaultValidation,
            firebase: firebaseValidation,
          }[currentMethod]
        }
        handleLogin={handleLogin}
      />
    );
  }
}
