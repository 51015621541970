import jsPsychInstructionsResponse from "@jspsych/plugin-instructions";

import { instructionImage } from "../../lib/utils";

/**
 * Builds a trial that displays a given set of instructions
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Array} pages The pages of instructions (as strings) to be displayed
 * @returns
 */
export function buildInstructionsTrial(jsPsych, pages) {
  return {
    type: jsPsychInstructionsResponse,
    data: { name: "instructions" },
    show_page_number: true,
    pages,
    key_forward: "/",
    key_backward: "z",
  };
}

/**
 * Builds the trial for displaying the quiz specific game instructions
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Score} score The Score instance used to keep track of the user's score within the mission
 * @param {Array} images An array of images shown as the instructions
 */
export function buildQuizInstructions(jsPsych, score, images) {
  return {
    type: jsPsychInstructionsResponse,
    data: { name: "instructions" },
    pages: images.map((image) => instructionImage(image)),
    on_finish: (data) => score.assignScore(data),
    key_forward: "/",
    key_backward: "z",
  };
}
