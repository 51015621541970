import PropTypes from "prop-types";
import React from "react";
import { Button, Form, Image } from "react-bootstrap";
import { checkEnvOrUrl, getSearchParam } from "../utils";

export default function Login({ validationFunction, handleLogin }) {
  // State variables for login screen (Can be initialized from the ENV or URL)
  const [studyID, setStudyID] = React.useState(() => checkEnvOrUrl("STUDY_ID") ?? "");
  const [participantID, setParticipantID] = React.useState(
    () => checkEnvOrUrl("PARTICIPANT_ID") ?? ""
  );
  const [sessionID, setSessionID] = React.useState(() => checkEnvOrUrl("SESSION_ID") ?? "");

  // State variable for handling errors
  const [isError, setIsError] = React.useState(false);
  // State variable for handling loading states
  const [isLoading, setIsLoading] = React.useState(false);

  // Initialize login information on the first render
  // TODO: What's the best place for this check?
  React.useEffect(() => {
    // Error if the default Prolific environment variable is found
    if (process.env.REACT_APP_PROLIFIC_PID) {
      // NOTE: This variable comes from Prolific no the ENV - this error will likely never happen
      throw new Error(
        `The environment variable "REACT_APP_PROLIFIC_PID" is not supported, received ${process.env.REACT_APP_PROLIFIC_PID}.\n` +
          `Please change the variable to "REACT_APP_PARTICIPANT_ID"`
      );
    }

    // Error if the default Prolific URL search parameter is found
    if (getSearchParam("PROLIFIC_PID")) {
      throw new Error(
        `The URL search parameter "PROLIFIC_PID" is not supported, received ${getSearchParam("PROLIFIC_PID")}.\n` +
          `Please change the variable to "PARTICIPANT_ID"`
      );
    }
  }, []);

  // Function used to validate and log in participant
  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    // Logs user in if a valid participant/study id combination is given
    validationFunction(studyID, participantID, sessionID).then((isValid) => {
      setIsLoading(false);
      setIsError(!isValid);

      if (isValid) handleLogin(studyID, participantID, sessionID);
    });
  }

  return (
    <div className="centered-h-v">
      {/* Title Image */}
      <Image
        src="/assets/images/gearshift-title.svg"
        alt="Gearshift title image"
        className="width-50"
      />

      {/* Login Form */}
      <Form onSubmit={handleSubmit} className="width-50 d-grid">
        <Form.Group size="lg" controlId="participantID">
          <Form.Label>Participant ID</Form.Label>
          <Form.Control
            autoFocus
            name="participantID"
            required={true}
            value={participantID}
            onChange={(e) => setParticipantID(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="studyID">
          <Form.Label>Study ID</Form.Label>
          <Form.Control
            name="studyID"
            required={true}
            value={studyID}
            onChange={(e) => setStudyID(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="sessionID">
          <Form.Label>Session ID</Form.Label>
          <Form.Control
            name="sessionID"
            value={sessionID}
            onChange={(e) => setSessionID(e.target.value)}
            disabled={true}
          />
        </Form.Group>
        <Button type="submit" variant="secondary" size="lg">
          {isLoading ? "Submitting..." : "Log In"}
        </Button>
        {isError ? (
          <Form.Text className="alert alert-danger">
            Unable to begin the study. Is your login information correct?
          </Form.Text>
        ) : null}
      </Form>

      {/* Copyright information */}
      <div>© 2024 Nadja R. Ging-Jehli. All rights reserved.</div>
    </div>
  );
}

Login.propTypes = {
  validationFunction: PropTypes.func,
  handleLogin: PropTypes.func,
};
