import { DRIVER_FILE } from "./constants";

/**
 * The HTML string for the stimulus of the main task
 * NOTE: This is run before the experiment begins, can only initialize some of the data
 * @param {Object} trialConfig The configuration object for a specific trial
 * @param {Number} missionNumber The number of the given mission
 * @param {Number} missionNumTrials How many trials there are in the mission
 * @param {number} trialConfig.trialNumber The number of the trial within the mission
 * @param {string} trialConfig.agentFile The src path of the agent
 * @param {string} trialConfig.cueFile The src path of the cue (target car)
 * @param {string} trialConfig.stimulusFile The src path of the stimulus
 * @returns The HTML stimulus jsPsych displays for the trial
 */
export function createStimulus(
  missionNumber,
  missionNumTrials,
  { trialNumber, agentFile, cueFile, stimulusFile }
) {
  return `
    <div id="parentWrapper">
      <div id="header">
        <div id="headerMission" class="hudBox">
          <p>Mission ${missionNumber}</p>
        </div>
        <div id="headerProgress" class="hudBox">
          <p>${trialNumber} / ${missionNumTrials}</p>
          <p>Progress</p>
        </div>
        <div id="headerReward" class="hudBox">
          <div class="hudScore"> 
            <p id="missionRewardText"></p>
            <img src="assets/images/hud/star.svg" alt="A cute star" />
          </div>
          <p>Points</p>
        </div>
        <div id="headerSign" class="hudBox">
          <img
            id="stimulusImage"
            class="invisible"
            src="${stimulusFile}"
            alt="The dynamically generated stimulus file"
          />
        </div>
        <div id="headerBomb" class="hudBox invisible"></div>
        <div id="headerTruck" class="hudBox"></div>
        <div id="headerLives" class="hudBox">
          <div class="hudScore"> 
            <p id="missionLivesText"></p>
            <img src="assets/images/hud/heart-full.svg" alt="A cute heart" />
          </div>
          <p>Lives</p>
        </div>
      </div>
      <div id="gameWrapper">
        <div id="gameBoard">
          <div id="driverCar">
            <div id="backDriverCar">
              <img id="driverImage" src="${DRIVER_FILE}" alt="driverCar image">
              <div id="driverScore" class="invisible"></div>
            </div>
          </div>
          <div id="targetCar">
            <div id="backTargetCar">
              <img
                id="targetImage"
                src="assets/images/targetCars/${cueFile}"
                alt="An image of the target car" 
              />
            </div>
          </div>
          <div id="highwayPointer"></div>
          <div id="allHighwayDividers"></div>
        </div>
      </div>
      <div id="footer">
        <div id="footerFuel" class="hudBox">
          <div class="gaugeBox">
            <img
              src="/assets/images/hud/GasAndLabelsGeneric.svg"
              alt="An image of a fuel gauge"
              class="hudImage gauge"
            />
            <img
              src="/assets/images/hud/speed-needle.svg"
              alt="An image of a gauge needle"
              id="gasNeedle"
              class="hudImage gaugeNeedle"
            />
          </div>
        </div>
        <div id="footerAgent" class="hudBox">
          <img 
            id="agentImage" 
            class="hudImage invisible" 
            src="/assets/images/headquarter/${agentFile}" 
            alt="Dynamic image generated that represents headquarter communication"
          />
        </div>
        <div id="footerSpeed" class="hudBox">
          <div class="gaugeBox">
            <img
              src="/assets/images/hud/SpeedAndLabelsGeneric.svg"
              alt="An image of a speedometer"
              class="hudImage gauge"
            />
            <img
              src="/assets/images/hud/speed-needle.svg"
              alt="An image of a gauge needle"
              id="speedNeedle"
              class="hudImage gaugeNeedle"
            />
          </div>
        </div>
      </div>
    </div>
    `;
}

/**
 * Shows a given domElement by removing its "invisible" class
 * @param {HTMLElement} element
 */
export function showElement(element) {
  element.classList.remove("invisible");
}

/**
 * Hides a given element by adding the "invisible" class
 * @param {HTMLElement} element
 */
export function hideElement(element) {
  element.classList.add("invisible");
}
