import jsPsychInstructionsResponse from "@jspsych/plugin-instructions";
import { instructionImage } from "../../lib/utils";

/**
 * Displays the mission feedback screen as an instructions trial
 * @param {string} image The image to display
 */
export function buildAchievementsTrial(image) {
  return {
    type: jsPsychInstructionsResponse,
    data: { name: "achievements" },
    pages: [instructionImage(image)],
    key_forward: "/",
    key_backward: "z",
  };
}
