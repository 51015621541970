import { initJsPsych } from "jspsych";
import PropTypes from "prop-types";
import React from "react";

import { config, taskName, taskVersion } from "../../config/main";
import { buildTimeline, jsPsychOptions } from "../../experiment";
import { initParticipant } from "../deployments/firebase";

// ID used to identify the DOM element that holds the experiment.
const EXPERIMENT_ID = "experiment-window";

export default function JsPsychExperiment({
  loginInformation: { studyID, participantID, sessionID },
  metadata,
  dataUpdateFunction,
  dataFinishFunction,
}) {
  const [jsPsych, setJsPsych] = React.useState();
  const [timeline, setTimeline] = React.useState();

  /**
   * Create the instance of JsPsych whenever the studyID or participantID changes, which occurs then the user logs in.
   *
   * This instance of jsPsych is passed to any trials that need it when the timeline is built.
   */
  React.useEffect(() => {
    async function initializeJsPsych() {
      // Start date of the experiment - used as the UID of the session
      const startDate = new Date().toISOString();

      // Write the initial record to Firestore
      if (config.USE_FIREBASE) initParticipant(studyID, participantID, startDate);

      // Initialize the instance of jsPsych that will be used on the next render
      const nextJsPsych = initJsPsych({
        // Combine necessary Honeycomb options with custom ones (src/timelines/main.js)
        ...jsPsychOptions,
        display_element: EXPERIMENT_ID,
        on_data_update: (data) => {
          jsPsychOptions.on_data_update && jsPsychOptions.on_data_update(data); // Call custom on_data_update function (if provided)
          dataUpdateFunction(data); // Call Honeycomb's on_data_update function
        },
        on_finish: (data) => {
          jsPsychOptions.on_finish && jsPsychOptions.on_finish(data); // Call custom on_finish function (if provided)
          dataFinishFunction(data); // Call Honeycomb's on_finish function
        },
      });

      // Adds experiment data into jsPsych directly. These properties will be added to all trials
      nextJsPsych.data.addProperties({
        task_name: taskName,
        task_version: taskVersion,
        start_date: startDate,
        study_id: studyID,
        participant_id: participantID,
        session_id: sessionID,
        task_metadata: metadata,
      });

      setJsPsych(nextJsPsych);
    }

    initializeJsPsych();
  }, [metadata]);

  /** Asynchronously builds the timeline once jsPsych is initialized */
  React.useEffect(() => {
    if (!jsPsych) return; // Early exit when jsPsych hasn't been initialized (initial render)

    async function initializeTimeline() {
      // Build the timeline that will be used on the next render
      const nextTimeline = await buildTimeline(
        jsPsych,
        studyID,
        participantID,
        sessionID,
        metadata
      );
      setTimeline(nextTimeline);
    }
    initializeTimeline();
  }, [jsPsych]);

  /** Runs the experiment once the timeline is initialized */
  React.useEffect(() => {
    if (!timeline) return; // Early exit when timeline hasn't been initialized (initial render)
    jsPsych.run(timeline);
  }, [timeline]);

  return <div id={EXPERIMENT_ID} />;
}

JsPsychExperiment.propTypes = {
  loginInformation: PropTypes.exact({
    studyID: PropTypes.string,
    participantID: PropTypes.string,
    sessionID: PropTypes.string,
  }),
  metadata: PropTypes.object,
  dataUpdateFunction: PropTypes.func,
  dataFinishFunction: PropTypes.func,
};
