import { image } from "./markup/tags";

/**
 * Pauses program execution for a given amount of time
 * @param {number} ms The number of milliseconds to sleep for
 * @returns A resolved promise after ms milliseconds
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Add a random number between 0 and offset to the base number
 * @param {number} base The starting number
 * @param {number} offset The maximum addition to base
 * @returns The base number jittered by the offset
 */
export function jitter(base, offset) {
  return base + Math.floor(Math.random() * Math.floor(offset));
}

/**
 * Add a random number between 0 and 50 to the base number
 * @param {number} base The starting number
 * @returns The base number jittered by 50
 */
export function jitter50(base) {
  return jitter(base, 50);
}

/**
 * Flips a coin
 * @returns Returns true or false randomly
 */
export function randomTrue() {
  return Math.random() > 0.5;
}

/**
 * Deeply copies an object
 * @param {Object} obj The starting object
 * @returns An exact copy of obj
 */
export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Format a number as a US dollar amount
 * @param {number} amount Dollar amount
 * @returns The string representation of amount in USD
 */
export function formatDollars(amount) {
  return "$" + parseFloat(amount).toFixed(2);
}

/**
 * Interleave a value before/after every element in an array
 * @param {Array<any>} arr The original array
 * @param {any} val The value to interleave inside the array
 * @param {boolean} addBefore Whether to add val before or after each element in array
 * @returns The original array with val interleaved between every element
 */
export function interleave(arr, val, addBefore = true) {
  return [].concat(...arr.map((n) => (addBefore ? [val, n] : [n, val])));
}

/**
 * Retrieves JSON data from an external URL
 * @param {string} url The URL to fetch data from
 * @returns {Object} The JSON data fetched from the URL
 */
export async function loadExternalJSON(url) {
  try {
    // Fetch the data from the URL
    const response = await fetch(url, { cache: "no-store" });

    // Check if the response is ok (status is 200-299)
    if (!response.ok) {
      console.error("Bad response when loading data:", response);
      throw new Error("Network response was not ok: " + response.statusText);
    }
    return await response.json();
  } catch (error) {
    // TODO: Failover JSON file in the repo if unable to load the data
    // Handle any errors that occur during the fetch or response parsing
    console.error("There has been a problem with your fetch operation:", error);
    throw new Error("Failed to load the file at " + url);
  }
}

/**
 * Randomly retrieves a single element from an array
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Array} array A given array of elements
 * @returns
 */
export function getRandomElement(jsPsych, array) {
  return jsPsych.randomization.sampleWithoutReplacement(array, 1)[0];
}

/**
 * Retrieves the data object from the current trial in JsPsych
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @returns
 */
export function getCurrentTrialData(jsPsych) {
  return jsPsych.getCurrentTrial().data;
}

/**
 * Retrieves the data object from the last trial in JsPsych
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @returns {Object}
 */
export function getLastTrialData(jsPsych) {
  const dataCollection = jsPsych.data.getLastTrialData();
  return dataCollection.trials[0];
}

/**
 * Generates an image tag specific to the Gearshift instruction pages
 * @param {string} src The path to the image's src
 */
export function instructionImage(src) {
  return image({
    src,
    alt: "An image with some instructions",
    style: "width: 85%; height: 85%",
  });
}

/**
 * Calculates the number of pixels a given percentage of the screen's width is
 * @param {number} percent What percentage of the screen width we want (0-1)
 * @returns The width of the user's screen multiplied by the percentage
 */
export function getScreenWidth(percent = 1) {
  return screen.width * percent;
}

/**
 * Calculates the number of pixels a given percentage of the screen's height is
 * @param {number} percent What percentage of the screen height we want (0-1)
 * @returns The height of the user's screen multiplied by the percentage
 */
export function getScreenHeight(percent = 1) {
  return screen.height * percent;
}

/**
 * Retrieves a given mission config fail
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The metadata being used to build the experiment
 * @param {number} missionNumber Which mission we're getting the config for
 * @returns
 */
export async function getGearshiftMissionConfig(jsPsych, metadata, missionNumber) {
  const index = missionNumber - 1;
  const missionConfigUrls = metadata.missionTaskName[index].taskName;

  // Array is empty, we're skipping the mission
  if (missionConfigUrls.length === 0) return undefined;

  const missionConfigUrl = getRandomElement(jsPsych, missionConfigUrls);
  const missionConfig = await loadExternalJSON(missionConfigUrl);

  // Ensure missionConfig object is for mission one
  if (missionConfig.mission !== missionNumber) {
    console.error("Invalid missionConfig object passed to mission 1:", missionConfig);
    throw new Error("An invalid mission configuration object was passed to mission 1");
  }

  return missionConfig;
}
